import React from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import Layout from '../components/Layout';
import PageTemplateDetails from '../components/PageTemplateDetails';

import { MetaFragment } from '../components/Meta';

const PageTemplate = props => {
  const { wp, wpPage, file } = props.data;
  const { title: siteTitle, url: siteUrl, language } = wp.generalSettings;
 
  const { 
    title: pageTitle,
    featuredImage: wpFeaturedImage, 
    author, 
    date, 
    modified,
    link: pageLnk,
    seo,
  } = wpPage;

  const featuredImage = {
    node: {
      mediaItemUrl: siteUrl + file.publicURL,
      mediaDetails: {
        width: 1920,
        height: 1080,
      },
    },
  }; 

  Object.entries(seo).forEach(([key, value]) => {
    if (!value || value.length === 0)
      delete seo[key];
  });

  const title = seo.title ?? `${pageTitle} - ${parse(siteTitle)}`;
  const link = siteUrl + pageLnk;
  const description =  seo.metaDesc ?? wpPage.description;

  const type = seo.opengraphType ?? `article`;
  const ogImg = image => image ?? featuredImage.node;
  const tagNames = seo.metaKeywords?.split(',');

  const jsonLd = {
    "@context": `https://schema.org/`,
    "@type": type,
    author: {
      "@type": `Person`,
      name: author.node.nickname || author.node.name,
      image: author.node.image?.avatar ? author.node.image.avatar.url : undefined,
    },
    url: seo.opengraphUrl ?? link,
    keywords: tagNames,
    headline: pageTitle,
    datePublished: seo.opengraphPublishedTime ?? moment(new Date(date)).format('DD.MM.YYYY HH:mm:ss'),
    dateModified: seo.opengraphModifiedTime ?? modified,
    publisher: {
        "@type": `Organization`,
        name: siteTitle,
        logo: {
            "@type": `ImageObject`,
            url: featuredImage.node.mediaItemUrl,
            width: featuredImage.node.mediaDetails.width,
            height: featuredImage.node.mediaDetails.height,
        },
    },
    image: {
      "@type": `ImageObject`,
      url: ogImg(wpFeaturedImage?.node).mediaItemUrl,
      width: ogImg(wpFeaturedImage?.node).mediaDetails.width,
      height: ogImg(wpFeaturedImage?.node).mediaDetails.height,
    },
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <html lang={language} />
          <title>{title}</title>
          <link rel="canonical" href={link} />
          <link rel="pingback" href="https://xmalanderssein.de/xmlrpc" />
          <meta name="tags" {...(tagNames ? { content: tagNames.join(',') } : {})} />          
          <meta name="description" content={description} />
          <meta name="robots" content="index,follow,max-image-preview:large,max-snippet:-1,max-video-preview:-1" />
          <meta property="og:site_name" content={siteTitle} />
          <meta property="og:type" content={type} />
          <meta property="og:title" content={seo.opengraphTitle ?? title} />
          <meta property="og:description" content={seo.opengraphDescription ?? description} />
          <meta property="og:url" content={link} />
          <meta name="twitter:title" content={seo.twitterTitle ?? (seo.opengraphTitle ?? title)} />
          <meta name="twitter:description" content={seo.twitterDescription ?? (seo.opengraphDescription ?? description)} />
          <meta name="twitter:url" content={link} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={ogImg(wpFeaturedImage?.node).mediaItemUrl} />
          <meta property="og:image" content={ogImg(wpFeaturedImage?.node).mediaItemUrl} />
          <meta property="og:image:width" content={ogImg(wpFeaturedImage?.node).mediaDetails.width} />
          <meta property="og:image:height" content={ogImg(wpFeaturedImage?.node).mediaDetails.height} />
					<meta name="google-site-verification" content="l5FCVcaO9LO7rocX46rGmx_wtbWEjqUOTQE4DibrMJA" />
          <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
        </Helmet>
        <PageTemplateDetails {...props} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    file(name: {eq: "logo-wp"}) {
      publicURL
    }
    site {
      siteMetadata {   
        adminUrl     
        rss
        robots
      }
    }
    allWpComment(
      filter: {
          commentedOn: {
              node: {id: {eq: $id}}
          }, 
          parentId: {eq: null}
      }
  ) {
      edges {
        node {
          date(formatString: "DD.MM.YYYY")
          databaseId
          content
          author {
            node {
              gravatarId
              name
              url
            }
          }
          replies {
            nodes {
              author {
                node {
                  gravatarId
                  name
                  url
                }
              }
              content
              databaseId
              date(formatString: "DD.MM.YYYY")
              replies {
                nodes {
                  author {
                    node {
                      gravatarId
                      name
                      url
                    }
                  }
                  content
                  databaseId
                  date(formatString: "DD.MM.YYYY")
                  replies {
                    nodes {
                      author {
                        node {
                          gravatarId
                          name
                          url
                        }
                      }
                      content
                      databaseId
                      date(formatString: "DD.MM.YYYY")
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wp {
      generalSettings {
        description
        language
        title
        url
      }
    }       
    wpPage(id: { eq: $id }) {
      id
      databaseId
      uri
      title
      date(formatString: "DD. MMMM YYYY", locale: "de")
      slug
      content
      commentCount
      commentStatus 
      modified(formatString: "DD.MM.YYYY HH:mm:ss")
      link
      seo {
        ...WpPostTypeSEOFragment
      }
      featuredImage {
        node {
          mediaDetails {
            height
            width
          }
          mediaItemUrl
        }
      }
      author {
        node {
          name
          nickname
          avatar {
            url
          }
        }
      }      
    }
    allWpPage {
      edges {
        node {
          uri
          title
        }
      }
    }
    allWpCategory {
      nodes {
        name
        uri
      }
    }
  }
`;
