import React, {useState} from 'react';
import Header from './Header';
import LatestComments from './LatestComments';
import CommentIcon from './CommentIcon';
import LatestPosts from './LatestPosts';
import Comments from './Comments';
import Footer from './Footer';
import TtsButton from './TtsButton';

const PageTemplateDetails = props => {  
  const { wpPage, allWpCategory, allWpComment } = props.data;
  
  const { 
    title, 
    content, 
    databaseId,
    commentCount,
    commentStatus,
  } = wpPage;  

  const categoryNames = allWpCategory.nodes
    .map(node => ({ name: node.name, uri: node.uri }))
    .filter(({ name }) => name !== 'Uncategorized');

  const commentActive = commentStatus === 'open';
  const [count, setCount] = useState(commentCount ?? 0);
  const handleCountUpdate = num => setCount(num);

  return (
    <>
      <Header title={title} />                   
      <main className="js-blog-posts">                
        <article className="post">              
          <section className="longform drop container container--wide">
            <header>                       
              <p>
                <span className="comments">
                  { commentActive && <CommentIcon count={count} /> }               
                  <TtsButton text={content} />
                </span> 
              </p>                      
            </header>  
            <div
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </section>
        </article>
      </main>
      { commentActive && <Comments data={allWpComment.edges} id={databaseId} onCountUpdate={handleCountUpdate} /> }
      <div className="container container--wide" style={{display: 'flex', gap: '1em', flexWrap: 'wrap'}}>
        <LatestPosts />
        <LatestComments />                        
      </div>   
      <Footer categories={categoryNames}  />
    </>
  );
};

export default PageTemplateDetails;
